<template>
  <div>
    <div class="row align--center p-75">
      <div
        class="flex xs12" :class="[edit ? 'md10' : 'md8']">
        <va-input
          v-model="term"
          :placeholder="$t('tables.actions.search')"
          :disabled="edit || loading"
          :removable="!edit"
          :error="!!termErrors"
          :error-messages="[termErrors]"
          @blur="checkTerm"
        />
      </div>
      <div class="flex xs12 md2">
        <va-select
          v-model="type"
          :label="$t('language.inputs.translation_type')"
          :options="translationTypes"
          :disabled="edit || loading"
          key-by="value"
          text-by="name"
          noClear
        />
      </div>
      <div class="flex xs12 md2" v-if="!edit">
        <va-button
          flat
          small
          color="primary"
          icon="fa fa-search"
          :disabled="loading"
          @click="searchLanguage"
        >
          {{ $t('tables.actions.search_translation') }}
        </va-button>
      </div>
      <div class="flex xs12 md6" v-for="lang of languages" :key="lang.name">
        <va-input
          :label="$t(`language.${lang.name}`)"
          v-model="lang.value"
          :disable="loading"
        />
      </div>
      <div class="flex xs12" v-if="hasLanguage">
        <va-button
          color="primary"
          :disabled="loading"
          @click="updateData"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
      <div class="flex xs12 text-center" v-else>
        <strong>{{ $t('language.modals.fill_path_and_search') }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { loadLanguageAsync } from '@/i18n'

export default {
  name: 'TranslatorModal',
  props: {
    edit: {
      type: Boolean,
      default: () => false,
    },
    translationType: {
      type: String,
      default: 'web',
    },
    path: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      loading: false,
      type: '',
      term: '',
      languages: [],
      selectedType: 0,
      termErrors: null,
    }
  },
  computed: {
    ...mapGetters(['currentLocale']),
    translationTypes () {
      return [
        { name: this.$t('languages.types.web'), value: 0 },
        { name: this.$t('languages.types.app'), value: 1 },
        { name: this.$t('languages.types.web_app'), value: -1 },
      ]
    },
    hasLanguage () {
      return this.languages.length > 0
    },
  },
  watch: {
    term (val) {
      this.termErrors = null
      this.languages = []
    },
  },
  created () {
    this.initialValues()
  },
  methods: {
    checkTerm () {
      const valid = this.term.match(/([^a-zA-Z._\-0-9])+/g) === null
      if (!valid) {
        this.termErrors = this.$t('forms.errors.no_special_characters')
      }
      return valid
    },
    initialValues () {
      let type = 0
      switch (this.translationType) {
        case 'app': type = 1; break
        case 'both': type = -1; break
      }

      this.type = this.translationTypes.find(x => x.value === type)
      if (this.path) {
        this.term = this.path
        this.searchLanguage()
      }
    },
    parse (data) {
      const keys = Object.keys(data)
      const d = []
      for (const k of keys) {
        d.push({ name: k, value: data[k] })
      }
      return d
    },
    async searchLanguage () {
      if (!this.checkTerm()) return

      this.loading = true

      let u = false
      const term = this.term.replace(/\s+/g, '.') // .toLowerCase()
      this.term = term
      try {
        u = await this.$http.get(`langs/single?type=${this.type.value}&q=${term}`)
      } catch (err) {
        // console.log('Error fetching language data', err)
        this.loading = false
        return
      }

      this.selectedType = this.type.value
      this.languages = this.parse(u.data.data)
      this.loading = false
    },
    async updateData () {
      if (!this.checkTerm()) return

      this.loading = true
      const data = {}
      for (const l of this.languages) {
        data[l.name] = l.value
      }

      const term = this.term.replace(/\s+/g, '.') // .toLowerCase()
      try {
        await this.$http.post(`langs/update?type=${this.selectedType}&q=${term}`, data)
      } catch (err) {
        // console.log('Error fetching language data', err)
        this.loading = false
        return
      }

      await loadLanguageAsync(this.currentLocale, true)
      this.loading = false
      this.$emit('saved', data)
    },
  },
}
</script>

<style lang="scss" scoped>
.p-75 {
  .flex {
    padding: $layout-padding;
  }
}
</style>
